import React, { useMemo } from 'react';

import { PRICING_PAGE_URL } from '../../../shared/constants.platform';
import { globalState } from '../../../shared/foreground/models';
import { SubscriptionProduct } from '../../../shared/types';
import styles from './TrialExpiryNotice.module.css';

export const TrialExpiryNotice = ({ inSidebar = false }: {inSidebar?: boolean;}) => {
  const profile = globalState((state) => state.client.profile);
  const subscription = useMemo(() => profile?.subscription, [profile]);

  const isTrial = useMemo(() => subscription?.product === SubscriptionProduct.Trial || subscription?.product === SubscriptionProduct.Expired, [subscription]);

  if (!profile || !subscription || !isTrial) {
    return null;
  }

  return <div className={`${styles.trialMessageContainer} ${inSidebar ? styles.sidebar : ''} ${subscription.trial_days_left <= 7 ? styles.endingSoon : ''} `}>
    {subscription.trial_days_left > 0 && subscription.product !== SubscriptionProduct.Expired ? <span>
      You have {subscription.trial_days_left} day{subscription.trial_days_left !== 1 ? `s` : ''} left in your free trial. <a href={PRICING_PAGE_URL} target="_blank" rel="noreferrer">Upgrade your account {`->`}</a>
    </span>
    : <span>Your trial has expired. <a href={PRICING_PAGE_URL} target="_blank" rel="noreferrer">Upgrade your account {`->`}</a></span>
    }
  </div>;
};
